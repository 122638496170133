import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1530.000000 1530.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1530.000000) scale(0.100000,-0.100000)" >

<path d="M7445 15294 c-1790 -55 -3465 -712 -4817 -1891 -167 -145 -512 -487
-670 -663 -1047 -1167 -1706 -2615 -1898 -4167 -71 -584 -71 -1262 0 -1846
188 -1521 820 -2933 1837 -4099 145 -167 487 -512 663 -670 1157 -1038 2599
-1699 4130 -1893 623 -78 1297 -78 1920 0 1531 194 2973 855 4130 1893 176
158 518 503 663 670 1017 1166 1649 2578 1837 4099 71 584 71 1262 0 1846
-192 1552 -851 3000 -1898 4167 -158 176 -503 518 -670 663 -1262 1101 -2824
1755 -4467 1871 -218 16 -587 25 -760 20z m860 -318 c228 -23 376 -43 572 -76
1341 -229 2581 -822 3623 -1733 148 -130 537 -519 667 -667 1086 -1242 1714
-2756 1823 -4388 5 -85 10 -292 10 -462 0 -334 -9 -510 -41 -805 -148 -1365
-695 -2684 -1563 -3769 -194 -243 -308 -369 -555 -617 -248 -247 -374 -361
-617 -555 -1063 -851 -2349 -1393 -3684 -1553 -338 -41 -438 -46 -890 -46
-452 0 -552 5 -890 46 -1453 175 -2830 794 -3960 1782 -148 130 -537 519 -667
667 -1086 1242 -1714 2756 -1823 4388 -13 198 -13 726 0 924 101 1512 652
2934 1594 4112 194 243 308 369 555 617 248 247 374 361 617 555 1144 915
2555 1475 3989 1584 77 6 158 12 180 14 112 10 911 -3 1060 -18z"/>
<path d="M7170 14579 c-1555 -114 -2984 -723 -4155 -1769 -140 -125 -483 -473
-601 -610 -678 -787 -1158 -1668 -1439 -2640 -134 -465 -219 -948 -255 -1452
-13 -189 -13 -727 0 -916 109 -1517 675 -2897 1664 -4057 139 -163 472 -503
631 -645 896 -801 1939 -1344 3090 -1610 331 -76 721 -133 1087 -160 189 -13
727 -13 916 0 951 68 1825 308 2657 730 517 262 952 552 1400 934 180 154 598
571 751 751 992 1166 1555 2540 1664 4057 13 189 13 727 0 916 -110 1532 -681
2912 -1694 4092 -132 154 -559 579 -721 716 -606 516 -1249 905 -1975 1194
-651 259 -1352 417 -2082 470 -170 12 -769 11 -938 -1z m1080 -93 c1618 -150
3098 -844 4245 -1991 1063 -1063 1738 -2413 1950 -3895 49 -344 59 -515 59
-950 0 -521 -27 -823 -115 -1285 -398 -2089 -1770 -3894 -3689 -4855 -772
-387 -1572 -613 -2460 -697 -261 -24 -919 -24 -1180 0 -471 44 -896 125 -1330
252 -1932 564 -3547 1985 -4363 3835 -300 682 -480 1385 -554 2160 -25 261
-25 919 0 1180 132 1390 633 2631 1498 3705 706 878 1650 1592 2675 2025 741
312 1441 474 2314 534 138 9 785 -3 950 -18z"/>
<path d="M4515 11040 c-446 -48 -795 -265 -1015 -630 -90 -150 -143 -316 -159
-500 -29 -347 84 -675 325 -939 193 -212 428 -366 1014 -664 446 -227 570
-301 683 -407 l62 -58 -1 -374 c-1 -434 -9 -510 -57 -581 -38 -56 -127 -105
-253 -138 -101 -27 -292 -36 -405 -19 -271 39 -563 201 -790 437 -201 210
-339 473 -437 832 l-38 136 -50 3 -51 3 -6 -182 c-9 -234 -9 -1131 0 -1316 l6
-143 53 0 54 0 24 48 c41 80 97 135 176 171 69 32 77 33 159 29 70 -4 107 -13
196 -48 205 -78 300 -112 395 -140 282 -82 681 -84 955 -4 l90 27 830 7 c487
5 871 12 930 19 241 27 567 108 785 196 125 50 193 86 313 164 43 28 79 51 82
51 2 0 1 -17 -2 -37 -9 -57 -47 -125 -92 -166 -87 -79 -184 -107 -373 -107
l-128 0 0 -55 0 -55 323 0 c1062 -2 3022 1 3024 4 2 2 2 107 1 235 l-3 231
-70 -51 c-118 -87 -231 -132 -410 -164 -121 -22 -486 -30 -750 -17 -247 12
-298 25 -363 96 -52 56 -70 116 -82 266 -13 169 -13 3062 0 3244 11 153 26
199 85 272 68 83 172 114 408 121 l157 6 0 52 0 52 -347 6 c-190 4 -712 7
-1160 7 l-814 0 3 -62 3 -63 155 -6 c126 -5 166 -10 211 -28 124 -48 201 -125
218 -218 5 -26 9 -49 8 -50 -1 0 -45 26 -97 59 -212 134 -483 242 -732 293
-285 57 -541 75 -1090 75 l-378 0 0 46 0 45 -52 -3 c-51 -3 -52 -3 -66 -45
l-14 -43 -364 0 c-360 0 -365 0 -451 25 -48 14 -126 32 -173 41 -104 18 -347
26 -455 14z m412 -255 c143 -27 294 -89 418 -171 62 -42 62 -41 75 -274 11
-178 12 -1092 2 -1160 l-7 -46 -355 181 c-566 287 -685 360 -836 510 -143 143
-194 248 -194 400 0 270 209 500 509 560 92 18 292 18 388 0z m1933 -96 c135
-12 289 -42 364 -69 77 -29 225 -131 304 -208 319 -315 481 -811 508 -1552 22
-638 -98 -1224 -322 -1567 -141 -216 -329 -353 -582 -420 -86 -23 -117 -26
-272 -27 -158 -1 -181 1 -234 21 -33 13 -72 35 -88 50 -61 58 -61 57 -69 863
-4 404 -4 1227 -1 1828 l5 1092 136 0 c75 0 188 -5 251 -11z"/>
<path d="M7175 6058 c-223 -500 -242 -534 -302 -553 -14 -4 -23 -14 -23 -26 0
-18 8 -19 155 -19 146 0 155 1 155 19 0 15 -10 21 -42 26 -24 4 -49 12 -55 17
-20 16 -15 79 12 136 l24 52 149 0 149 0 32 -71 c17 -40 31 -82 31 -95 0 -27
-26 -44 -69 -44 -24 0 -31 -4 -31 -20 0 -19 7 -20 225 -20 210 0 225 1 225 18
0 11 -13 23 -35 31 -24 8 -43 26 -59 54 -13 23 -102 219 -197 435 -94 215
-177 392 -184 392 -6 0 -78 -150 -160 -332z m325 -69 c188 -426 214 -477 254
-495 27 -12 9 -13 -149 -13 -114 0 -172 4 -158 9 50 20 49 50 -10 183 l-26 57
-164 0 -165 0 -27 -64 c-45 -105 -36 -156 29 -175 23 -7 0 -9 -89 -10 l-120
-1 30 19 c60 36 58 33 295 564 87 196 136 294 141 285 4 -8 76 -169 159 -359z"/>
<path d="M7225 6048 c-7 -18 -38 -88 -68 -155 -30 -67 -52 -124 -49 -127 10
-10 282 -7 282 4 0 19 -133 310 -142 310 -5 0 -15 -15 -23 -32z m84 -134 c30
-68 51 -127 49 -130 -3 -2 -55 -3 -116 -2 l-111 3 57 127 c31 71 59 128 62
128 3 0 29 -57 59 -126z"/>
<path d="M8975 6358 c-86 -204 -351 -778 -370 -804 -14 -18 -41 -38 -60 -45
-22 -8 -35 -20 -35 -31 0 -17 13 -18 160 -18 151 0 160 1 160 19 0 15 -10 21
-42 26 -49 8 -78 29 -78 56 0 11 12 48 26 84 l26 65 152 0 151 0 33 -78 c18
-42 32 -85 32 -95 0 -19 -37 -37 -76 -37 -17 0 -24 -5 -24 -20 0 -19 7 -20
225 -20 215 0 225 1 225 19 0 13 -9 21 -29 26 -53 11 -58 22 -266 495 -167
379 -172 390 -186 390 -5 0 -16 -15 -24 -32z m205 -398 c176 -400 195 -437
235 -462 30 -18 29 -18 -145 -18 -149 0 -171 2 -150 13 35 18 32 72 -10 165
l-33 72 -163 0 -164 0 -25 -57 c-37 -83 -41 -122 -16 -155 12 -15 34 -29 49
-31 15 -2 -29 -4 -98 -5 -92 -1 -118 2 -100 9 63 25 66 31 417 819 l24 55 13
-30 c8 -16 82 -185 166 -375z"/>
<path d="M8838 5927 c-38 -84 -68 -156 -68 -160 0 -4 66 -7 146 -7 135 0 146
1 140 18 -31 83 -133 302 -141 302 -5 0 -40 -69 -77 -153z m137 -12 c26 -60
50 -116 52 -122 4 -10 -22 -13 -111 -13 -64 0 -116 2 -116 5 0 18 115 254 120
248 4 -4 28 -57 55 -118z"/>
<path d="M1628 6366 c-160 -43 -240 -214 -168 -357 30 -57 111 -123 228 -183
172 -88 212 -126 212 -204 0 -57 -46 -109 -109 -123 -129 -29 -270 69 -317
220 -33 106 -44 81 -44 -104 0 -98 4 -165 10 -165 5 0 20 11 34 25 31 31 44
31 131 0 139 -49 281 -26 366 58 56 56 79 108 79 183 0 134 -66 206 -290 319
-108 54 -155 89 -174 130 -56 116 97 203 235 134 54 -27 116 -103 136 -165 24
-77 43 -71 43 14 0 93 -12 232 -19 232 -4 0 -12 -14 -18 -31 -11 -28 -15 -31
-45 -26 -18 3 -64 17 -103 31 -78 28 -117 31 -187 12z"/>
<path d="M13457 6370 c-162 -41 -249 -200 -187 -344 29 -69 94 -123 242 -203
129 -70 173 -103 197 -150 27 -53 -1 -128 -61 -159 -39 -21 -118 -23 -172 -4
-78 26 -161 124 -190 224 -6 22 -17 41 -24 44 -9 3 -12 -36 -12 -162 0 -99 4
-166 10 -166 5 0 20 11 34 25 14 14 34 25 44 25 11 0 50 -11 86 -25 175 -66
362 -1 426 147 18 42 21 64 17 115 -9 122 -65 179 -290 299 -128 68 -171 103
-183 150 -15 58 26 115 94 133 115 31 256 -66 293 -201 6 -24 16 -38 26 -38
13 0 15 8 9 53 -3 28 -6 96 -6 150 0 99 -10 121 -29 67 -13 -38 -36 -39 -119
-5 -70 28 -150 38 -205 25z"/>
<path d="M2478 6351 c-81 -26 -135 -61 -196 -125 -192 -203 -148 -557 88 -708
107 -68 289 -89 422 -48 143 44 260 172 297 325 16 63 16 189 1 246 -29 109
-124 230 -219 279 -101 51 -284 66 -393 31z m202 -32 c118 -27 173 -131 186
-349 11 -177 -21 -327 -84 -400 -45 -54 -80 -70 -147 -70 -104 0 -157 44 -203
168 -20 55 -26 91 -30 198 -6 157 7 246 49 331 40 83 88 118 179 132 3 0 25
-4 50 -10z"/>
<path d="M5887 6363 c-15 -14 -6 -33 17 -33 42 0 87 -22 97 -47 5 -13 9 -183
9 -378 0 -405 2 -393 -83 -402 -37 -5 -47 -9 -47 -24 0 -18 13 -19 258 -19
279 0 339 8 434 56 208 106 288 377 179 611 -16 34 -48 82 -72 107 -52 57
-161 112 -249 126 -72 11 -533 14 -543 3z m603 -38 c158 -50 252 -161 282
-332 30 -180 -48 -367 -187 -449 -86 -50 -177 -64 -414 -64 -180 0 -212 2
-191 13 48 24 50 37 50 419 0 202 -4 368 -10 382 -5 14 -22 32 -37 41 -26 14
-11 15 212 12 194 -3 251 -7 295 -22z"/>
<path d="M6220 5936 c0 -373 1 -387 20 -406 15 -15 33 -20 73 -20 102 0 187
70 223 183 25 79 30 287 10 380 -35 158 -113 234 -250 244 l-76 6 0 -387z
m177 337 c43 -20 84 -75 115 -152 20 -50 22 -74 22 -216 0 -143 -2 -166 -23
-218 -41 -103 -108 -156 -198 -157 -76 0 -73 -15 -73 402 l0 371 61 -7 c33 -3
77 -13 96 -23z"/>
<path d="M7754 6357 c-2 -7 -3 -65 -2 -128 3 -107 4 -114 24 -117 18 -3 23 5
32 40 26 100 77 141 177 142 l60 1 0 -377 c0 -426 4 -406 -79 -415 -36 -5 -46
-9 -46 -24 0 -18 12 -19 240 -19 233 0 240 1 240 20 0 16 -7 20 -34 20 -19 0
-45 9 -60 21 l-26 20 0 380 0 379 52 0 c98 0 159 -48 189 -147 8 -31 18 -43
31 -43 17 0 18 10 18 130 l0 130 -405 0 c-325 0 -407 -3 -411 -13z m795 -114
l0 -108 -20 52 c-37 95 -96 133 -211 133 l-58 0 0 -388 c0 -418 0 -421 54
-442 13 -5 -43 -9 -149 -9 -155 0 -168 1 -143 15 45 24 48 60 46 456 l-3 363
-52 3 c-113 7 -184 -39 -220 -140 l-18 -53 -3 99 c-1 55 0 106 2 113 4 10 81
13 391 13 l385 0 -1 -107z"/>
<path d="M3180 6345 c0 -10 10 -15 34 -15 19 0 45 -9 60 -21 l26 -20 0 -373
c0 -422 2 -410 -75 -422 -116 -17 -16 -24 349 -24 l393 0 12 113 c7 61 14 131
18 155 9 69 -18 52 -49 -31 -33 -87 -73 -138 -131 -166 -36 -17 -62 -21 -154
-21 -94 0 -112 3 -126 18 -15 17 -17 58 -17 385 l0 366 26 20 c17 13 41 21 65
21 28 0 39 4 39 15 0 13 -33 15 -235 15 -202 0 -235 -2 -235 -15z"/>
<path d="M4077 6354 c-13 -13 5 -24 39 -24 24 0 43 -8 59 -25 l25 -24 0 -368
c0 -422 3 -408 -80 -419 -110 -15 -38 -24 185 -24 129 0 235 4 235 9 0 4 -19
11 -41 14 -23 3 -51 15 -63 26 -20 21 -21 33 -25 312 -3 309 3 447 23 474 7
10 32 19 59 22 31 4 47 10 47 19 0 11 -42 14 -228 14 -126 0 -232 -3 -235 -6z"/>
<path d="M4590 6345 c0 -10 10 -15 31 -15 32 0 69 -15 81 -34 4 -6 8 -180 8
-387 0 -425 4 -405 -79 -416 -23 -3 -41 -10 -41 -14 0 -5 114 -9 253 -9 224 0
261 2 327 21 89 24 140 53 197 109 75 75 113 180 113 311 0 188 -86 334 -235
400 -83 37 -184 48 -427 49 -196 0 -228 -2 -228 -15z m471 -52 c51 -10 130
-95 152 -163 43 -133 44 -329 2 -451 -20 -58 -78 -127 -122 -145 -40 -17 -135
-18 -151 -2 -9 9 -12 109 -12 390 l0 378 49 0 c27 0 64 -3 82 -7z"/>
<path d="M9770 6345 c0 -10 10 -15 31 -15 32 0 69 -15 81 -34 4 -6 8 -180 8
-387 0 -425 4 -405 -79 -416 -23 -3 -41 -10 -41 -14 0 -5 178 -9 395 -9 217 0
395 3 395 8 0 4 7 63 15 132 19 157 19 170 0 170 -8 0 -15 -7 -15 -15 0 -27
-60 -149 -88 -179 -49 -52 -94 -66 -219 -66 -104 0 -113 1 -127 22 -14 19 -16
73 -16 381 l0 358 25 24 c18 18 35 25 65 25 29 0 40 4 40 15 0 13 -33 15 -235
15 -202 0 -235 -2 -235 -15z"/>
<path d="M10670 6345 c0 -10 10 -15 34 -15 19 0 45 -9 60 -21 l26 -20 0 -373
c0 -422 2 -410 -75 -422 -102 -15 -32 -24 185 -24 127 0 230 4 230 9 0 4 -18
11 -41 14 -83 11 -79 -9 -79 418 l0 378 26 20 c15 12 41 21 60 21 24 0 34 5
34 15 0 13 -32 15 -230 15 -198 0 -230 -2 -230 -15z"/>
<path d="M11180 6345 c0 -9 9 -15 25 -15 15 0 41 -15 65 -36 l40 -36 0 -345
c0 -388 -1 -394 -68 -412 -20 -6 -43 -11 -49 -11 -7 0 -13 -4 -13 -10 0 -6 60
-10 160 -10 153 0 203 10 123 25 -55 10 -81 30 -93 72 -7 25 -10 139 -8 327
l3 289 298 -366 c164 -202 304 -367 312 -367 13 0 15 57 15 409 0 396 1 409
20 434 12 15 36 29 55 33 20 3 35 12 35 20 0 11 -28 14 -145 14 -116 0 -145
-3 -145 -14 0 -8 16 -16 39 -20 48 -7 78 -41 86 -99 3 -23 5 -127 3 -231 l-3
-188 -220 276 -220 276 -157 0 c-134 0 -158 -2 -158 -15z"/>
<path d="M12167 6354 c-13 -13 5 -24 39 -24 24 0 43 -8 59 -25 l25 -24 0 -360
c0 -248 -4 -368 -11 -385 -12 -26 -54 -46 -95 -46 -13 0 -24 -4 -24 -10 0 -6
82 -10 225 -10 124 0 225 4 225 8 0 4 -20 13 -44 20 -59 15 -66 39 -66 217 0
123 2 144 18 158 16 15 24 8 122 -115 173 -216 187 -248 120 -262 -17 -3 -30
-10 -30 -16 0 -6 80 -10 230 -10 134 0 230 4 230 9 0 5 -16 15 -36 21 -20 7
-54 28 -75 47 -43 39 -379 456 -379 469 1 11 331 274 373 296 18 9 42 17 55
18 13 0 22 6 22 15 0 13 -26 15 -175 15 -149 0 -175 -2 -175 -15 0 -10 10 -15
33 -15 34 0 67 -24 67 -49 0 -25 -42 -65 -217 -208 l-178 -144 -3 160 c-3 192
5 224 63 237 20 5 38 14 38 19 2 11 -425 20 -436 9z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
